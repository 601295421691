html {
    --mainColor1: #394855;
    --mainColor2: #9dadbc;
    --mainColor3: #8d99ae;
    --mainColor4: #cfd8dc;
    --mainColor5: #eceff1;
    --mainColor6: #c0c0c0;
    --mainColor7: #696969;
    --mainFontColor: #111111;
    --mainBgColor: white;
    --mainFontFamily1: Lato, sans-serif;
    --mainFontFamily2: "Lato black", sans-serif;
    --mainModalBgColor: rgb(10, 122, 173);
    --mainModalFontColor: white;
}

@font-face {
    font-family: "Open Sans Semibold";
    src: url("/src/fonts/OpenSans-Semibold.ttf") format("truetype");
}

/*
Analytics Design Guidelines

    Added by Mikko Karppinen, last edited by Mikko Karppinen on Oct 22, 2021
*/

/*
COLORS
------

PAGE BACKGROUND: #ECEFF1

Graphics Set 1: 0a7aad, 429abd, 5faac5, 7ebacc, 72c3cf, 66ccdd

Set 2: 394855, 9dadbc, 8d99ae, cfd8dc, eceff1, c0c0c0, 696969

Set 3: 8d68b2, ab5ea4, c2558e
*/

body {
    /* font-family: sans-serif; */
    /* margin: 3rem; */
    margin: 0;
    background-color: #eceff1;
}

.set1 {
    color: #0a7aad;
    color: #429abd;
    color: #5faac5;
    color: #7ebacc;
    color: #72c3cf;
    color: #66ccdd;
}

.set2 {
    color: #394855;
    color: #9dadbc;
    color: #8d99ae;
    color: #cfd8dc;
    color: #eceff1;
    color: #c0c0c0;
    color: #696969;
}

.set3 {
    color: #8d68b2;
    color: #ab5ea4;
    color: #c2558e;
}

/*
Fonts and headers
-----------------

Heading fonts:

Lato Black

#111111
*/

/* https://www.cssfontstack.com/Lato */
@import url("https://fonts.googleapis.com/css?family=Lato");
@import url("https://fonts.googleapis.com/css?family=Open+Sans");

body {
    font-family: Lato, sans-serif;
    /* margin: 3rem; */
    margin: 0;
    background-color: #eceff1;
}

h1 {
    font-family: Lato, sans-serif;
    font-size: 24px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
}
h2 {
    font-family: Lato, sans-serif;
    font-size: 18px; /* changed 2022-02-21 */
    /* font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 26.4px; */
    color: #111111;
    margin-bottom: 8px;
}
h3 {
    font-family: Lato, sans-serif;
    font-size: 14px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 700;
    line-height: 15.4px; */
    color: #111111;
    margin-bottom: 8px;
}
p {
    font-family: Lato, sans-serif;
    /* font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 20px; */
    color: #111111;
}
blockquote {
    font-family: Lato, sans-serif;
    /* font-size: 21px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 30px; */
    color: #111111;
}
pre {
    font-family: Lato, sans-serif;
    /* font-size: 13px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    color: #111111;
}

/* used in GenericChart for axis fonts */
text {
    font-family: Lato, sans-serif;
    font-size: 10px;
    /*font-style: normal;
    font-variant: normal;
    font-weight: 400;
    line-height: 18.5667px; */
    /* color: #111111; */
}

/*
Metrics
-------

Metric font:

Lato Black

#111111

Metric name:

Open Sans Semibold

#394855
*/

.metricFont {
    font-family: Lato, sans-serif;
    color: #111111;
}

.metricName {
    font-family: Open Sans;
    color: #394855;
}

/*
Selector and dropdowns
----------------------

Open Sans Semibold

#696969
*/

/*
Paragraph and texts
-------------------

Open Sans

#272727
*/
