.homeNav {
    background: white;
    color: black;
}

.homeNavLink {
    list-style: none;
    float: right;
}

.homeNavLink > a {
    color: black;
    font-weight: bold;
    text-decoration: none;
}
