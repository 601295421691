* {
    box-sizing: border-box;
}

.row {
    margin-left: -5px;
    margin-right: -5px;
}

.column {
    float: left;
    width: 50%;
    padding: 5px;
}

/* Clearfix (clear floats) */
.row::after {
    content: "";
    clear: both;
    display: table;
}

.resultItemTitle {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
    height: 140px; /* controls height of boxes in table */
}

.assetImage {
    cursor: pointer;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    /* color: "#394855"; */
    background-color: white;

    /* border: 1px solid #ddd; */
}

th,
td {
    text-align: left;
    padding: 16px;
}

tr {
    border-bottom: 1px solid #ddd;
}

/* tr:nth-child(even) {
    background-color: #f2f2f2;
} */

/* BORROWED FROM Assets.module.css */

.Assets_loader {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.Assets_loader div {
    margin: 200px auto;
}

.Assets_loader div svg {
    background: #eceff1;
}

.Assets_homeLink {
    width: 90%;
    margin: auto;
}

.Assets_homeLink a {
    float: right;
}

.Assets_modal {
    /* box-shadow: 0 1px 4px rgb(0 0 0 / 20%); */
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 40rem;
    left: calc(50% - 20rem);
    height: 28rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
}

.Assets_modal_title {
    color: white;
    text-align: center;
}

.Assets_modal > h2 {
    padding: 20px;
    margin: 0;
    /* border-bottom: 1px solid #555; */
}

.Assets_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
}

.container_2videos {
    color: var(--mainFontColor);
    width: 70%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.Assets_dashboard_title {
    width: 100%;
}

.Assets_main {
    margin: 30px auto;
}

.Assets_main h2 {
    color: var(--mainFontColor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-bottom: 0;
    font-size: 1.5rem;
}

.Assets_main_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px;
    gap: 10px;
    position: relative;
    width: 100%;
}

.Assets_main_info button {
    padding: 10px 15px !important;
}

.Assets_main_info a {
    height: 100%;
    border: none;
    color: var(--mainFontColor);
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    right: 10%;
}

.Assets_main_info a:hover {
    cursor: pointer;
    color: var(--mainFontColor);
    transition: all ease 0.4s;
}

.Assets_main_info h2 {
    display: inline-block;
    white-space: nowrap;
}

.Assets_main_info h2,
.Assets_main_info button {
    padding: 10px 30px;
}

.Assets_main h2::after {
    display: none;
}

.container_3videos {
    color: var(--mainFontColor);
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    font-family: var(--mainFontFamily2);
}

.AssetTitle {
    font-size: 12px; /* changed 2022-02-21 */
    font-weight: 600;
}

.Assets {
    background-color: var(--mainBgColor);
    color: var(--mainFontColor);
    border-radius: 10px;
}

.Assets:hover {
    cursor: pointer;
}

.Asset {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mainFontColor);
    padding-bottom: 5px;
    padding-top: 5px;
    max-height: 98px;
    min-height: 70px;
}

.AssetTitle {
    padding-bottom: 0px;
    overflow: hidden;
    align-items: flex-start !important;
    max-height: 34px;
}

.AssetInfo {
    font-size: 10px; /* changed 2022-02-21 */
    color: var(--mainFontColor);
    padding-bottom: 0px;
}

.AssetDuration {
    font-size: 10px; /* changed 2022-02-21 */
    margin-top: 10px;
}

.AssetInfo,
.AssetTitle,
.AssetDuration {
    width: 90%;
    margin: 5px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}

.AssetTitle:hover {
    text-decoration: underline;
    color: var(--mainFontColor);
}

.icon {
    font-size: 13px;
}

.event {
    width: 80%;
    margin: 0 auto;
    padding-top: 30px;
}

.event h2 {
    color: var(--mainFontColor);
    padding-bottom: 0;
}

.iframe {
    /* height: 80vh; */
    border: none;
    margin: 10px 0;
}

.ViewedSectionSecondary__2videos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(350px, 100%), 300px));
    align-items: flex-start;
    column-gap: 30px;
    row-gap: 20px;
}

.ViewedSectionSecondary__3videos {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 350px));
    align-items: flex-start;
    column-gap: 30px;
    row-gap: 20px;
    justify-content: center;
}

.NoDataAvailable {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.NoDataAvailable h3 {
    font-size: 1.3rem;
}

.NoDataAvailable img {
    width: 200px;
}

.modalItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.modalItem_button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
}

.modalItem_button button {
}

.modalItem_button_confirm {
    background-color: var(--mainYellow);
    color: var(--mainBlack);
}

.modalItem_button_confirm:hover {
    background-color: var(--clr-primary-4);
    color: var(--mainWhite);
}

.modalItem_button_cancel:hover {
    background-color: var(--mainHover);
    color: var(--mainWhite);
}

.Assets_main_info_filter_addFilter,
.Assets_main_info_filter_removeFilter {
    display: inline-block;
    background: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
    color: var(--mainModalFontColor);
    background-color: var(--mainModalBgColor);
}

.Assets_main_info_filter_addFilter:hover .filterIcon {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_main_info_filter_addFilter:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_main_info_filter_removeFilter:hover .removeIcon {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

.Assets_main_info_filter_removeFilter:hover {
    background-color: var(--mainModalBgColor);
    color: var(--mainModalFontColor);
}

/* filter icon */
.filterIcon {
    color: var(--mainModalFontColor);
}

.filterIcon:hover {
    color: var(--mainModalFontColor);
    cursor: pointer;
}

.removeIcon {
    color: red;
}

.Assets_main_info_button {
    display: inline-block;
    position: relative;
}

.Assets_main_remove_button {
}

.Assets_main_info_header {
    display: block;
}

.Assets_main_info_header h2 {
    white-space: nowrap;
}

.Assets_main_info_search {
    display: block;
    position: relative;
    margin-right: 20px;
}

.Assets_main_info_search_box {
    display: block;
    text-align: right;
    padding: 5px 10px;
}

.Assets_main_info_search_box input {
    font-size: 16px;
}

.Assets_main_info_date_range {
    font-family: var(--mainFontFamily2);
}

.Assets_main_info_search_date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Assets_search_icon {
    font-size: 16px;
    display: inline-block;
    margin-left: 5px;
}

@media (max-width: 1050px) {
    .container_2videos {
        width: 100%;
    }
}

@media (max-width: 850px) {
    .ViewedSectionSecondary__2videos {
        grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 300px));
    }

    .container_2videos {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .container_2videos {
        width: 80%;
        margin: 0 auto;
    }
}
