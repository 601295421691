.resultItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.resultItem:not(:last-of-type) {
    border-right: #394855 1px solid;
}

.resultItemTitle {
    font-size: 12px; /* changed 2022-02-21 */
    /*font-weight: 600;*/
    color: #394855;
    margin-bottom: 2%;
}

.amountNumber {
    font-family: "Lato black", sans-serif;
    font-size: 30px; /* changed 2022-02-21 */
    font-weight: 900;
}

.loader {
    height: 40px; /* changed 2022-02-21 */
}
