.actions {
    text-align: right;
}

/* was .content-wrapper */
.container {
    width: 100%;
    /* width: 960px; */
    /* overflow-x: hidden;*/
    /*overflow-y: auto; */

    /* max-width: 500px;
    margin: 30px auto;
    overflow: auto;
    min-height: 300px;
    border: 1px solid steelblue;
    padding: 30px;
    border-radius: 5px; */
}

.view {
    width: 95%;
    margin: 0 auto;
    /*height: 100%; */
    /* overflow: auto; */
}

.Assets_loader {
    background-color: #eceff1;
    width: 100%;
    height: 100%;
}

.Assets_loader div {
    margin: 200px auto;
}

.Assets_loader div svg {
    background: #eceff1;
}
