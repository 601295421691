.select {
    /* float: right; */
    padding: 8px 0px 0px 8px;
    /* cursor: pointer; */
    font-size: 14px;
    font-family: inherit;
    text-align: left;
    width: 12rem;

    display: inline-block;
    /* background: #000;
    color: #fff; */
    /* border: none; */
    padding: 9px 18px;
    /* margin: 5px; */
    margin-bottom: 20px;
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
    border-radius: 3px;
    /* cursor: pointer; */
    /* text-decoration: none; */
    font-size: 12px; /* changed 2022-02-21 */
    font-family: inherit;
}
